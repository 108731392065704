<template>
  <CheckboxGroup :value="value" :disabled="disabled" @input="updateValue">
    <Checkbox
      v-for="fieldValue in field.values"
      :key="fieldValue.id"
      :label="fieldValue.id"
      :class="{ 'checkbox-required': fieldValue.required }"
    >
      <div class="checkbox-agreement-name">
        <p>{{ fieldValue.confirmationText }}</p>
        <router-link
          :key="fieldValue.id"
          :to="localePath({ path: `/registration/juridical/${fieldValue.id}` })"
          class="content-items__link agreement-link"
          target="_blank"
        >
          {{ fieldValue.contentName }}
        </router-link>
        <span v-if="fieldValue.required" class="checkbox-agreement-required"
          >*</span
        >
        <div
          v-if="shouldShowWarning(fieldValue)"
          class="checkbox-agreement-warning"
        >
          {{ fieldValue.warningText }}
        </div>
        <Button
          v-if="shouldShowWarning(fieldValue)"
          type="primary"
          size="small"
          :disabled="fieldValue.readWarn"
          class="registration-footer__read-btn"
          @click.prevent="fieldValue.readWarn = true"
        >
          Я прочитал
        </Button>
      </div>
    </Checkbox>
  </CheckboxGroup>
</template>

<script>
import InitValidation from '../mixins/init-validation'
import UpdateValue from '../mixins/update-value'

export default {
  mixins: [UpdateValue, InitValidation],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    warningTextIsVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    shouldShowWarning(fieldValue) {
      return (
        this.warningTextIsVisible &&
        !this.value.includes(fieldValue.id) &&
        !fieldValue.readWarn &&
        fieldValue.warningText
      )
    },
  },
}
</script>

<style lang="scss">
.ivu-checkbox-group {
  .ivu-checkbox-group-item {
    display: flex;
    gap: 8px;
    &.checkbox-required {
      &:after {
        display: none;
      }
    }
  }
}
.checkbox-agreement-required {
  margin-left: 4px;
  line-height: 1;
  font-family: SimSun;
  font-size: 14px;
  color: #fb4e4e;
}
.checkbox-agreement-name {
  display: inline-block;
  position: relative;
  p {
    display: inline-block;
  }
  .agreement-link {
    padding-right: 0;
    margin-left: 4px;
  }
}
.checkbox-agreement-warning {
  margin-top: 10px;
  color: #f6a609;
  font-size: 16px;
}
</style>
